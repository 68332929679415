<template>
  <div>
      <section id="features" class="services-area pt-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="section-title text-center pb-40">
                        <div class="line m-auto"></div>
                        <h3 class="title">Desain bersih dan sederhana, <span>dengan semua fitur yang Anda butuhkan!</span> </h3>
                    </div> <!-- section title -->
                </div>
            </div> <!-- row -->
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-1.svg" alt="shape">
                            <i class="lni lni-baloon"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Mudah & Cepat</a></h4>
                            <p class="text">Mudah dipahami dan bisa langsung digunakan dengan cepat</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-2.svg" alt="shape">
                            <i class="lni lni-cog"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Manajemen Stok</a></h4>
                            <p class="text">Mengelola dan mengetahui posisi stok produk dengan cepat dari
                                mana saja</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-3.svg" alt="shape">
                            <i class="lni lni-bolt-alt"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Riwayat</a></h4>
                            <p class="text"> Analisa riwayat Transaksi pelanggan Anda
                                dengan mudah</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-1.svg" alt="shape">
                            <i class="lni lni-support"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Laporan Lengkap</a></h4>
                            <p class="text">Lihat laporan penjualan, stok dan pelanggan dengan cepat dan
                                realtime.</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-2.svg" alt="shape">
                            <i class="lni lni-shopping-basket"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Multi Toko</a></h4>
                            <p class="text">Mengelola dan mengetahui posisi stok produk dengan cepat dari
                                mana saja</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-3.svg" alt="shape">
                            <i class="lni lni-mobile"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Multi Platform</a></h4>
                            <p class="text"><span style="color:red">MYSTOKU </span> dapat dijalankan di
                                platform web juga android</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </section>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>