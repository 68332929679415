<template>
  <div class="wrapper">
    <top-header></top-header>

    <content-data class="content"></content-data>

    <footer-content></footer-content>
  </div>
</template>

<script>
import TopHeader from "./Header.vue";
import ContentData from "./Content.vue";
import FooterContent from "./Footer.vue";
export default {
  components: {
    TopHeader,
    ContentData,
    FooterContent
  }
};
</script>

<style>
.content {
  margin-bottom: 50px;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #000;
}
</style>
