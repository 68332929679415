<template>
  <div class="home">
        <!--====== SERVICES PART START ======:style="{ backgroundImage: 'url(' + require('@/assets/images/banner-bg.svg') + ')' }"-->
    <section id="home">
        <div id="home" class="header-hero bg_cover">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 pb-200">
                        <div class="header-hero-content text-center">
                            <h3 class="header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Mystoku - Point Of Sale</h3>
                            <h4 class="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">Jadikan bisnis anda Lebih Mudah</h4>
                            <p class="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">Kami membantu bisnis anda mempercepat proses penjualan dengan
                                aplikasi kasir berbasis self service.</p>
                            <a href="https://www.dashboard.mystoku.com/register" class="main-btn wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="1.1s">Coba Gratis</a>
                        </div> <!-- header hero content -->
                    </div>
                </div> <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s">
                            <!-- <img src="assets/images/header-hero.png" alt="hero"> -->
                            <!-- <img src="assets/images/self-service.jpg" alt="hero"> -->
                            <!-- <p>PhotoShoot</p> -->
                        </div> <!-- header hero image -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
            <div id="particles-1" class="particles"></div>
        </div> <!-- header hero -->
    </section>
    <section id="features" class="services-area pt-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="section-title text-center pb-40">
                        <div class="line m-auto"></div>
                        <h3 class="title">Desain bersih dan sederhana, <span>dengan semua fitur yang Anda butuhkan!</span> </h3>
                    </div> <!-- section title -->
                </div>
            </div> <!-- row -->
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-1.svg" alt="shape">
                            <i class="lni lni-baloon"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Mudah & Cepat</a></h4>
                            <p class="text">Mudah dipahami dan bisa langsung digunakan dengan cepat</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-2.svg" alt="shape">
                            <i class="lni lni-cog"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Manajemen Stok</a></h4>
                            <p class="text">Mengelola dan mengetahui posisi stok produk dengan cepat dari
                                mana saja</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-3.svg" alt="shape">
                            <i class="lni lni-bolt-alt"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Riwayat</a></h4>
                            <p class="text"> Analisa riwayat Transaksi pelanggan Anda
                                dengan mudah</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-1.svg" alt="shape">
                            <i class="lni lni-support"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Laporan Lengkap</a></h4>
                            <p class="text">Lihat laporan penjualan, stok dan pelanggan dengan cepat dan
                                realtime.</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-2.svg" alt="shape">
                            <i class="lni lni-shopping-basket"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Multi Toko</a></h4>
                            <p class="text">Mengelola dan mengetahui posisi stok produk dengan cepat dari
                                mana saja</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
                <div class="col-lg-4 col-md-7 col-sm-8">
                    <div class="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="services-icon">
                            <img class="shape" src="@/assets/images/services-shape.svg" alt="shape">
                            <img class="shape-1" src="@/assets/images/services-shape-3.svg" alt="shape">
                            <i class="lni lni-mobile"></i>
                        </div>
                        <div class="services-content mt-30">
                            <h4 class="services-title"><a href="#">Multi Platform</a></h4>
                            <p class="text"><span style="color:red">MYSTOKU </span> dapat dijalankan di
                                platform web juga android</p>
                            <!-- <a class="more" href="#">Baca Selengkapnya <i class="lni lni-chevron-right"></i></a> -->
                        </div>
                    </div> <!-- single services -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </section>
    
    <!--====== SERVICES PART ENDS ======-->
    
    <!--====== ABOUT PART START ======-->
    
    <section id="about" class="about-area pt-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="section-title">
                            <div class="line"></div>
                            <h3 class="title">Berjualan di toko dan <span> online sekaligus </span></h3>
                        </div> <!-- section title -->
                        <p class="text">Dengan database yang sama, menjamin produk, inventory dan laporan
                            yang tetap sinkron walaupun penjualan dilakukan dari program kasir,
                            toko online, maupun aplikasi mobile Anda.
                            <br />
                            <br />Integrasikan website anda menggunakan API dari MYSTOKU.
                            <!-- <a
                              class="text-orange-500 underline"
                              href="#"
                            >Pelajari Dokumentasi API</a> -->
                        </p>
                        <!-- <a href="#" class="main-btn">Pelajari Dokumentasi API</a> -->
                    </div> <!-- about content -->
                </div>
                <div class="col-lg-6">
                    <div class="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="@/assets/images/about1.svg" alt="about">
                    </div> <!-- about image -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
        <div class="about-shape-1">
            <img src="@/assets/images/about-shape-1.svg" alt="shape">
        </div>
    </section>
    
    <!--====== ABOUT PART ENDS ======-->
    
    <!--====== ABOUT PART START ======-->
    
    <section class="about-area pt-70">
        <div class="about-shape-2">
            <img src="@/assets/images/about-shape-2.svg" alt="shape">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 order-lg-last">
                    <div class="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="section-title">
                            <div class="line"></div>
                            <h3 class="title">Catatan Transaksi Anda Masih menggunakan Buku?</h3>
                        </div> <!-- section title -->
                        <p class="text">Aplikasi kasir Mystoku menyederhanakan proses penjualan usaha Anda dan mengelola transaksi menjadi lebih efisien.</p>
                        <!-- <a href="#" class="main-btn">Try it Free</a> -->
                    </div> <!-- about content -->
                </div>
                <div class="col-lg-6 order-lg-first">
                    <div class="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="@/assets/images/about2.svg" alt="about">
                    </div> <!-- about image -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </section>

    <!--====== VIDEO COUNTER PART START ======-->
    
    <section id="facts" class="video-counter pt-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="video-content mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img class="dots" src="@/assets/images/dots.svg" alt="dots">
                        <div class="video-wrapper">
                            <div class="video-image">
                                <img src="@/assets/images/video.svg" alt="video">
                            </div>
                        </div> <!-- video wrapper -->
                    </div> <!-- video content -->
                </div>
                <div class="col-lg-6">
                    <div class="counter-wrapper mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                        <div class="counter-content">
                            <div class="section-title">
                                <div class="line"></div>
                                <h3 class="title">Teknologi Terbaru</h3>
                            </div> <!-- section title -->
                            <p class="text">Dalam satu paket Sistem Mystoku Kini telah hadir fitur Self Service yang memungkinkan pelanggan anda memilih menu secara mandiri, self service merupakan suatu sistem kerja atau konsep suatu perusahaan atau toko dimana para pelanggan-nya menikmati produk atau jasa dari toko tersebut tanpa dilayani oleh pelayan maupun staff dari perusahaan atau toko tersebut</p>
                        </div> <!-- counter content -->
                    </div> <!-- counter wrapper -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </section>
    
    <!--====== VIDEO COUNTER PART ENDS ======-->
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
<style scoped>
.header-hero-content .header-sub-title {
    color: black !important;
}
.header-hero-content .header-title {
    color: black !important;
    font-size: 52px !important;
}
.header-hero-content .text {
    color: black !important;
}
@media (max-width: 767px){
    .header-hero-content .header-title {
        font-size: 24px !important;
        padding-top: 10px;
    }
}

.section-title .line {
    background: #000 !important;
}
.video-wrapper {
    box-shadow: unset !important;
}
</style>