<template>
  <div>
    <!--====== HEADER PART START ======-->
    <header class="header-area">
        <div :class="[sticky ? 'navbar-area sticky':'navbar-area']">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg">
                          <div class="sc-logo-wrapper">
                            <router-link :to="{ name: 'Landing'}"><a class="navbar-brand">
                                <img v-if="sticky == false" src="@/assets/images/logowhite.svg" alt="Logo" height="auto" width="140">
                                <img v-if="sticky == true" src="@/assets/images/logowarna.svg" alt="Logo" height="auto" width="140">
                            </a></router-link>
                            <svg v-if="sticky == false" width="400" height="200" viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg" class="uk-visible@m back">
                              <g transform="translate(150,-100)">
                                <path d="M57.8,-54.7C83.9,-47,120.2,-37.3,151.7,-5.1C183.2,27.1,209.8,81.8,194.3,115.5C178.8,149.3,121.2,162,65.7,183.6C10.1,205.2,-43.3,235.5,-73.4,217.7C-103.5,199.8,-110.2,133.6,-126.4,83.6C-142.6,33.6,-168.3,-0.3,-175.9,-43.9C-183.5,-87.4,-173.1,-140.6,-140.8,-147.2C-108.4,-153.9,-54.2,-113.9,-19.2,-91.1C15.9,-68.2,31.7,-62.5,57.8,-54.7Z" fill="#000"></path>
                              </g>
                            </svg>
                          </div>
                          <b-navbar-toggle target="nav-collapse">
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                            </button>
                          </b-navbar-toggle>

                          <b-collapse id="nav-collapse" is-nav v-if="isMobile">
                            <b-navbar-nav class="text-left">
                              <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#home')" class="pt-2"><b-nav-item href="#">Beranda</b-nav-item></router-link>
                              <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#features')" class="pt-2"><b-nav-item href="#" disabled>Fitur</b-nav-item></router-link>
                              <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#about')" class="pt-2"><b-nav-item href="#" disabled>Tentang</b-nav-item></router-link>
                              <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#facts')" class="pt-2"><b-nav-item href="#" disabled>Teknologi</b-nav-item></router-link>
                              <b-nav-item href="https://www.dashboard.mystoku.com" class="pt-2">Login</b-nav-item>
                            </b-navbar-nav>
                          </b-collapse>
                            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                <ul id="nav" class="navbar-nav ml-auto mr-auto">
                                    <li class="nav-item active">
                                        <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#home')"><a class="page-scroll" href="#home">Beranda</a></router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#features')"><a class="page-scroll" href="#features">Fitur</a></router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#about')"><a class="page-scroll" href="#about">Tentang</a></router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'Landing'}" @click.native="scrollFix('#facts')"><a class="page-scroll" href="#facts">Teknologi</a></router-link>
                                    </li>
                                    <li class="nav-item" id="a">
                                        <a data-scroll-nav="0" href="https://www.dashboard.mystoku.com" rel="nofollow">Login</a>
                                    </li>
                                </ul>
                            </div> 
                          
                            <div class="navbar-btn d-none d-sm-inline-block">
                                <a data-scroll-nav="0" href="https://www.dashboard.mystoku.com" rel="nofollow" style="padding-right: 28px; color: black;">Login</a>
                                <a href="https://www.dashboard.mystoku.com/register" class="btn btn-outline-secondary">Daftar Gratis</a>
                            </div>
                        </nav> 
                    </div>
                </div> 
            </div> 
        </div> 

    </header>
    <!--====== HEADER PART ENDS ======-->
  </div>
</template>

<script>
import Vue from "vue";
import { NavbarPlugin } from "bootstrap-vue";
Vue.use(NavbarPlugin);

export default {
  data() {
    return {
      scrollPosition: null,
      sticky: false,
      isMobile: false,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.isMobile == false) {
       if (this.scrollPosition > 0) {
         this.sticky = true;
       } else {
         this.sticky = false;
       }
      }
    },
    scrollFix(hashbang) {
      location.hash = hashbang;
    },
    onResize() {
      this.isMobile = window.innerWidth <= 768;
      if (this.isMobile === true) {
        this.sticky = true
      }else{
        this.sticky = false
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
};
</script>

<style>
.navbar {
  padding: unset !important;
}
.back {
  position: absolute;
  top: -31px;
  left: -92px;
  z-index: 5;
}
.sc-logo-wrapper {
  position: relative;
}
.sc-logo-wrapper a {
  position: relative;
  z-index: 10;
}
.navbar-nav .nav-item a {
  padding: unset !important;
}
@media (max-width: 959px) {
  /* .uk-visible\@m {
      display: none!important;
  } */
}
@media (max-width: 768px){
  .sticky .navbar {
    padding: 10px 0 !important;
  }
}
</style>
