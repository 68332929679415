<template>
    <div class="container">
        <div class="row" style="margin-top:80px; margin-bottom: 40px;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-4">
            <h2 style="color:black; font-weight:600;">Kebijakan Privasi</h2>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-5">
            <p style="text-align:justify;">
                Kebijakan Privasi berikut ini menjelaskan bagaimana kami mengumpulkan, menggunakan, memindahkan, mengungkapkan dan melindungi 
                informasi pribadi anda yang dapat diidentifikasi yang diperoleh melalui Aplikasi kami (sebagaimana didefinisikan di bawah). 
                Mohon anda membaca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa anda memahami bagaimana ketentuan Kebijakan 
                Privasi ini kami berlakukan. Kebijakan Privasi ini disertakan sebagai bagian dari Ketentuan Penggunaan kami. 
                Kebijakan Privasi ini mencakup hal-hal sebagai berikut:
            </p>

            <ul style="text-align:justify;">
                <li>1. Definisi</li>
                <li>2. Informasi yang kami kumpulkan</li>
                <li>3. Penggunaan informasi yang kami kumpulkan</li>
                <li>4. Pemberian informasi yang kami kumpulkan</li>
                <li>5. Penahanan informasi yang kami kumpulkan</li>
                <li>6. Keamanan</li>
                <li>7. Perubahan atas Kebijakan Privasi ini</li>
                <li>8. Pengakuan dan persetujuan</li>
                <li>9. Berhenti menerima e-mail</li>
                <li>10. Cara untuk menghubungi kami</li>
            </ul>

            <p style="text-align:justify;">
                Penggunaan anda atas aplikasi dan layanan kami tunduk pada Ketentuan Penggunaan dan Kebijakan Privasi ini 
                dan mengindikasikan persetujuan anda terhadap Ketentuan Penggunaan dan Kebijakan Privasi tersebut.<br>
            </p>

            <h3><br> Definisi</h3>
            <hr>
            <p style="text-align:justify;">
            <b>“Kami”</b> berarti EvenKita, suatu perusahaan yang didirikan berdasarkan hukum Negara Republik Indonesia. <b>“Aplikasi”</b> berarti suatu aplikasi piranti 
            lunak yang telah kami kembangkan yang merupakan suatu sarana untuk menemukan Layanan yang disediakan oleh pihak ketiga. <b>“Penyedia Layanan”</b> berarti 
            suatu pihak ketiga panitia acara (penerbit tiket) yang menyediakan Layanan melalui Aplikasi. <b>“Layanan”</b> berarti layanan jasa penjualan tiket dengan 
            menggunakan aplikasi yang ditawarkan oleh Penyedia Layanan melalui Aplikasi yang dapat mencakup (i) layanan penjualan tiket, (ii) informasi.
            <b>“Informasi Pribadi”</b> berarti informasi mengenai anda yang secara pribadi dapat diidentifikasi yang dikumpulkan melalui Aplikasi, seperti nama, 
            nomer KTP, alamat, tanggal lahir, pekerjaan, nomor telepon, alamat surat elektronik (e-mail) anda dan/atau sejenisnya, dan informasi lain yang 
            mungkin dapat mengidentifikasi orang tertentu yang sedang menggunakan Aplikasi. <b>“Situs web”</b> berarti situs web kami di <span style="color: #6498fe">www.mystoku.com.</span>
            </p>

            <h3><br> Informasi yang Kami Kumpulkan</h3>
            <hr>
            <p style="text-align:justify;">
            Kami mengumpulkan Informasi Pribadi tertentu dari anda agar Aplikasi dapat menemukan Layanan dari Penyedia Layanan. Anda akan langsung memberikan Informasi 
            Pribadi (sebagai contoh, saat anda mendaftar) dan beberapa informasi akan secara otomatis dikumpulkan ketika anda menggunakan Aplikasi.
            Ketika anda mengunjungi Situs web kami, administrator situs web kami akan memproses data teknis seperti alamat IP anda, halaman web yang pernah anda kunjungi, 
            browser internet yang anda gunakan, halaman web yang sebelumnya /selanjutnya anda kunjungi dan durasi setiap kunjungan/sesi yang memungkinkan kami untuk 
            mengirimkan fungsi-fungsi Situs web. <br>
            Sebagai tambahan, dalam beberapa hal, browser dapat menyarankan anda agar geo-location anda memungkinkan kami untuk memberikan anda suatu pengalaman yang lebih baik. 
            Dengan data teknis ini administrator-administrator Situs web kami dapat mengelola Situs web, misalnya dengan menyelesaikan kesulitan-kesulitan teknis atau 
            memperbaiki kemampuan untuk dapat diaksesnya bagian-baigan tertentu dari Situs web. Dengan cara ini, kami dapat memastikan bahwa Anda dapat (terus) 
            menemukan informasi pada Situs web dengan cara yang cepat dan sederhana. Informasi yang Anda berikan secara langsung. <br>
            Pada saat mendaftar pada Aplikasi, anda akan memberikan kepada kami alamat surat elektronik / surel (email), nama, foto KTP, nomor telepon, dan sandi akun anda.
            Ketika anda menggunakan Aplikasi untuk menemukan suatu layanan, anda memberikan informasi pada kami, yaitu lokasi dan tujuan anda. 
            Anda juga memberikan informasi kepada kami mengenai tiket yang anda beli dan biaya pembelanjaan anda ketika anda menggunakan layanan tiket online. 
            Ketika anda menggunakan Aplikasi kami, kami juga akan memproses data teknis anda seperti alamat IP, Identitas (ID) Perangkat atau alamat MAC, dan informasi 
            mengenai pabrikan, model, dan sistem operasi dari perangkat bergerak/mobile device anda. <br>
            Kami menggunakan data ini untuk memungkinkan kami untuk mengirimkan fungsi-fungsi dari Aplikasi, menyelesaikan kesulitan-kesulitan teknis, menyediakan bagi 
            anda versi Aplikasi yang benar dan terkini dan untuk meningkatkan fungsi Aplikasi. <br>
            Kami akan meminta nomor telepon seseorang yang dapat dihubungi oleh Penyedia Layanan untuk melengkapi pesanan anda ketika anda menggunakan Aplikasi untuk 
            menemukan suatu layanan tiket online. Anda harus mendapatkan persetujuan terlebih dahulu dari orang yang nomor teleponnya anda berikan pada kami untuk 
            memberikan nomor teleponnya pada kami dan untuk kami memberikan nomor telepon tersebut kepada Penyedia Layanan. Anda dapat memberikan kode rujukan (referral code) 
            kepada teman anda melalui Aplikasi, dimana, kami hanya akan menyiapkan suatu pesan untuk anda kirimkan atau anda terbitkan melalui penyedia media sosial 
            atau surel anda. <br>
            Anda dapat mengubah pesan yang telah kami siapkan sebelum anda mengirimkannya. Kami tidak akan mengumpulkan data teman anda. Informasi yang kami kumpulkan 
            ketika anda menggunakan Aplikasi. 
            Ketika anda menggunakan Aplikasi melalui perangkat bergerak / mobile device anda, kami akan melacak dan mengumpulkan informasi geo-location secara real-time. 
            Kami menggunakan informasi ini untuk memungkinkan anda untuk melihat Penyedia Layanan yang berada di daerah anda yang dekat dengan lokasi anda, mengatur 
            pembelian tiket dan mengirimkan informasinya kepada penerbit tiket yang diminta, dan untuk melihat Penyedia Layanan tiket yang diminta menginformasikan 
            pembayaran tiket dan verifikasi tiket secara real-time. <br>
            Kami juga dapat menggunakan informasi geo-location secara real-time ini untuk memberikan bantuan, menyelesaikan kesulitan-kesulitan teknis atau usaha yang 
            mungkin timbul pada saat anda menggunakan Aplikasi. Anda dapat mematikan informasi pelacak geo-location pada tingkatan perangkat untuk sementara waktu. 
            Perangkat bergerak/mobile anda akan memberitahukan anda ketika geo-location anda dilacak dengan menampilkan simbol panah GPS. Kami juga melacak dan 
            mengumpulkan informasi geo-location Penyedia Layanan. Ini berarti bahwa kami juga mengumpulkan informasi ketika anda berinteraksi dengan Penyedia Layanan 
            (penerbit tiket). Kami juga akan menggunakan informasi geo-location Penyedia Layanan dalam bentuk tanpa nama dan keseluruhan untuk mendapatkan informasi 
            statistic dan informasi pengelolaan dan untuk menyediakan untuk anda fungsi Aplikasi yang ditingkatkan.
            </p>

            <h3><br> Penggunaan Informasi yang Kami Kumpulkan</h3>
            <hr>
            <p style="text-align:justify;">
              Kami menggunakan surel, nama, nomor telepon, dan sandi akun anda untuk memverifikasi kepemilikan anda atas suatu akun, untuk berkomunikasi dengan 
              anda sehubungan dengan pesanan anda dan untuk memberikan anda informasi mengenai Aplikasi. 
              Kami juga dapat menggunakan nama, surel, dan nomor telepon anda untuk mengirimkan pesan, pembaharuan yang bersifat umum atas Aplikasi, 
              penawaran-penawaran khusus atau promosi-promosi. Kami menggunakan geo-location dan tujuan anda untuk menemukan Penyedia Layanan yang 
              berada di sekitar anda, untuk membantu Penyedia Layanan untuk memperhitungkan informasi dan untuk menganalisa pola penggunaan Aplikasi 
              untuk meningkatkan kinerja Aplikasi. Kami menggunakan informasi seperti tiket yang anda pernah beli dan biaya pembelanjaan anda untuk menentukan 
              apakah Aplikasi dapat menerima pesanan anda berdasarkan Ketentuan Penggunaan.
            </p>

            <h3><br> Pemberian Informasi yang Kami Kumpulkan</h3>
            <hr>
            <p style="text-align:justify;">
              Setelah menerima pesanan anda, kami akan memberikan informasi seperti nama, nomor telepon, lokasi, tujuan, geo-location, tiket yang akan dibeli dan/atau 
              biaya pembelanjaan anda kepada Penyedia Layanan yang menerima permintaan anda atas Layanan. 
              Informasi ini dibutuhkan oleh Penyedia Layanan untuk menghubungi anda, dan/atau menemukan anda dan/atau memenuhi pesanan tiket anda. <br>
              Kami juga akan memberikan nomor telepon dari pihak yang dapat dihubungi yang telah anda berikan kepada kami kepada Penyedia Layanan ketika 
              anda menggunakan Aplikasi untuk menemukan layanan penyedia tiket. Anda dengan ini setuju dan memberikan wewenang
              pada kami untuk memberikan Informasi Pribadi anda kepada Penyedia Layanan sebagai suatu bagian dari ketentuan Layanan. Walaupun informasi pribadi anda 
              secara otomatis akan dihapus dari perangkat bergerak milik Penyedia Layanan setelah anda menggunakan Layanan, terdapat kemungkinan dimana Penyedia 
              Layanan dapat menyimpan data anda di perangkat mereka dengan cara apapun. <br>
              Kami tidak bertanggung jawab atas penyimpanan data dengan cara tersebut dan anda setuju untuk membela, memberikan ganti rugi dan membebaskan kami dan 
              kami tidak akan bertanggung jawab atas segala penyalahgunaan Informasi Pribadi anda oleh Penyedia Layanan setelah berakhirnya Layanan yang diberikan. 
              Kami dapat mempekerjakan perusahaan-perusahaan dan orang perorangan pihak ketiga untuk memfasilitasi atau memberikan Aplikasi dan layanan-layanan 
              tertentu atas nama kami, untuk memberikan bantuan konsumen, memberikan informasi geo-location kepada Penyedia Layanan kami, untuk melaksanakan 
              layanan-layanan terkait dengan Situs web (misalnya tanpa pembatasan, layanan pemeliharaan, pengelolaan database, analisis web dan penyempurnaan 
              fitur-fitur Situs web) atau untuk membantu kami dalam menganalisa bagaimana Layanan kami digunakan atau untuk penasihat profesional dan auditor 
              eksternal kami, termasuk penasihat hukum, penasihat keuangan, dan konsultan-konsultan. <br>
              Para pihak ketiga ini hanya memiliki akses atas informasi pribadi anda untuk melakukan tugas-tugas tersebut atas nama kami dan secara kontraktual 
              terikat untuk tidak mengungkapkan atau menggunakan informasi pribadi tersebut untuk tujuan lain apapun. <br> 
              Kami tidak membagikan Informasi Pribadi anda kepada pihak manapun selain kepada Penyedia Layanan terkait dan perusahaan dan individu pihak ketiga yang 
              disebutkan di bagian di atas, tanpa persetujuan dari anda. 
              Namun demikian, kami akan mengungkapkan Informasi Pribadi anda sepanjang dimintakan secara hukum, atau diperlukan untuk tunduk pada ketentuan 
              perundang-undangan, peraturan-peraturan dan pemerintah, atau dalam hal terjadi sengketa, atau segala bentuk proses hukum antara anda dan kami, 
              atau antara anda dan pengguna lain sehubungan dengan, atau terkait dengan Layanan, atau dalam keadaan darurat yang berkaitan dengan kesehatan 
              dan/atau keselamatan anda. <br>
              Informasi Pribadi anda dapat dialihkan, disimpan, digunakan, dan diproses di suatu yurisdiksi selain Indonesia dimana server-server kami berada. 
              Anda memahami dan setuju atas pengalihan Informasi Pribadi anda ke luar Indonesia. Kami tidak menjual atau menyewakan Informasi Pribadi anda kepada pihak ketiga.
            </p>

            <h3><br> Penahanan Informasi yang Kami Kumpulkan</h3>
            <hr>
            <p style="text-align:justify;">
            Kami akan menahan informasi anda hingga anda menghapus akun anda pada Aplikasi.
            </p>

            <h3><br> Keamanan</h3>
            <hr>
            <p style="text-align:justify;">
            Kami tidak menjamin keamanan database kami dan kami juga tidak menjamin bahwa data yang anda berikan tidak akan ditahan/terganggu ketika sedang dikirimkan 
            kepada kami. Setiap pengiriman informasi oleh anda kepada kami merupakan risiko anda sendiri. Anda tidak boleh mengungkapkan sandi anda kepada siapapun. 
            Bagaimanapun efektifnya suatu teknologi, tidak ada sistem keamanan yang tidak dapat ditembus.
            </p>

            <h3><br> Perubahan atas Kebijakan Privasi ini</h3>
            <hr>
            <p style="text-align:justify;">
            Kami dapat mengubah Kebijakan Privasi ini untuk mencerminkan perubahan dalam kegiatan kami. Jika kami mengubah Kebijakan Privasi ini, kami akan memberitahu anda 
            melalui email atau dengan cara pemberitahuan di Situs web 1 hari sebelum perubahan berlaku. Kami menghimbau anda untuk meninjau halaman ini secara berkala untuk 
            mengetahui informasi terbaru tentang bagaimana ketentuan Kebijakan Privasi ini kami berlakukan. Lain-lain Bahasa. Kebijakan Privasi ini diatur oleh dan untuk 
            ditafsirkan dalam hukum Negara Republik Indonesia. Setiap dan seluruh sengketa yang timbul dari kebijakan privasi ini akan diatur oleh yurisdiksi eksklusif dari 
            Pengadilan Negeri.
            </p>

            <h3><br> Pengakuan dan Persetujuan</h3>
            <hr>
            <p style="text-align:justify;">
            Dengan menggunakan Aplikasi, anda mengakui bahwa anda telah membaca dan memahami Kebijakan Privasi ini dan Ketentuan Penggunaan dan setuju dan sepakat 
            terhadap penggunaan, praktek, pemrosesan dan pengalihan informasi pribadi anda oleh kami sebagaimana dinyatakan di dalam Kebijakan Privasi ini. 
            Anda juga menyatakan bahwa anda memiliki hak untuk membagikan seluruh informasi yang telah anda berikan kepada kami dan untuk memberikan hak kepada kami 
            untuk menggunakan dan membagikan informasi tersebut kepada Penyedia Layanan.
            </p>

            <h3><br> Berhenti menerima e-mail</h3>
            <hr>
            <p style="text-align:justify;">
            Kami memiliki kebijakan untuk memilih masuk/keluar dari database. Jika Anda ingin berhenti menerima email dari kami, silahkan klik link unsubscribe yang 
            disertakan pada masing-masing e-mail.
            </p>

            <h3><br> Cara untuk Menghubungi Kami</h3>
            <hr>
            <p style="text-align:justify;">
            Jika Anda memiliki pertanyaan lebih lanjut tentang privasi dan keamanan informasi Anda dan ingin memperbarui atau menghapus data Anda 
            maka silakan hubungi kami di: <span style="color: #6498fe">admin@mystoku.com</span>
            </p>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
p {
  font-size: 18px;
  color: black;
  margin: 0px 0px 20px;
}
li {
  font-size: 18px;
  margin: 0px 0px 7px;
}

</style>