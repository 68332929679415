import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../layout/Layout.vue";

import Home from '../views/Home.vue'
import Fitur from '../views/Fitur.vue'
import Testimoni from '../views/Testimoni.vue'
import Bantuan from '../views/Bantuan.vue'
import KebijakanPrivasi from '../views/KebijakanPrivasi.vue'
import SyaratKetentuan from '../views/SyaratKetentuan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/landing'
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Layout,
    redirect: "/landing/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: Home
      },
      {
        path: "fitur",
        name: "Fitur",
        component: Fitur
      },
      {
        path: "testimoni",
        name: "Testimoni",
        component: Testimoni
      },
      {
        path: "bantuan",
        name: "Bantuan",
        component: Bantuan
      },
      {
        path: "privasi",
        name: "KebijakanPrivasi",
        component: KebijakanPrivasi
      },
      {
        path: "ketentuan",
        name: "SyaratKetentuan",
        component: SyaratKetentuan
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
