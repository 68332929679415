<template>
<section id="features" class="services-area pt-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <h3>Pertanyaan Yang Sering Muncul (FAQ)</h3>
                </div>
                <div class="col-md-6 text-left pt-4">
                    <div>
                        <h5 block v-b-toggle.id-1 variant="info">Apakah Itu Mystoku ?</h5>
                        <b-collapse id="id-1" class="pt-2" visible>
                            <p>Mystoku - Point Of Sale Jadikan bisnis Anda lebih mudah Kami membantu bisnis Anda mempercepat proses penjualan dengan aplikasi kasir berbasis self service.</p>
                        </b-collapse>
                        <hr>
                    </div>
                    <div>
                        <h5 block v-b-toggle.id-2 variant="info">Bagaimana cara menggunakan Mystoku ?</h5>
                        <b-collapse id="id-2" class="pt-2" visible>
                            <p>Dengan cara mendaftar di Mystoku. Masukkan produkmu dan lengkapi info yang dibutuhkan. Setelah itu, anda siap mengelola usahamu</p>
                        </b-collapse>
                        <hr>
                    </div>
                    <div>
                        <h5 block v-b-toggle.id-3 variant="info">Fitur apa saja yang ada di Mystoku ?</h5>
                        <b-collapse id="id-3" class="pt-2" visible>
                            <p>Mystoku - Point Of Sale menyediakan sistem self service yang memungkinkan pelanggan anda memilih menu secara mandiri</p>
                        </b-collapse>
                        <hr>
                    </div>
                    <div>
                        <h5 block v-b-toggle.id-4 variant="info">Bagaimana saya mendapatkan laporan penjualan di Mystoku ?</h5>
                        <b-collapse id="id-4" class="pt-2" visible>
                            <p>Mystoku - Point Of Sale menyediakan fitur generate laporan berdasarkan tanggal, hari maupun bulan sehingga mempermudah anda dan tidak perlu repon menulis di buku</p>
                        </b-collapse>
                        <hr>
                    </div>
                    <div>
                        <h5 block v-b-toggle.id-5 variant="info">Pada kasir apakah Mystoku menyediakan pemanggilan pesanan?</h5>
                        <b-collapse id="id-5" class="pt-2" visible>
                            <p>Mystoku - Point Of Sale menyediakan fitur pemanggilan pesanan otomatis ketika pesanan selesai dibuat.</p>
                        </b-collapse>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
</section>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import { VBToggle } from 'bootstrap-vue'; 
Vue.component('b-collapse', BCollapse)
Vue.directive('b-toggle', VBToggle);
export default {
    data() {
        return {
        }
    }
}
</script>

<style>

</style>