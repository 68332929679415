<template>
  <div class="container">
    <div class="row" style="margin-top: 80px; margin-bottom: 40px">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-4">
        <h2 style="color: black; font-weight: 600">Syarat dan ketentuan</h2>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-5">
        <p style="text-align: justify">
          Selamat datang di Mystoku! Syarat dan ketentuan ini menguraikan aturan
          dan regulasi untuk penggunaan Situs Mystoku, yang terletak di
          www.mystoku.com. Dengan mengakses situs web ini, kami menganggap Anda
          menerima syarat dan ketentuan ini. Jangan terus menggunakan Mystoku
          jika Anda tidak setuju untuk mengambil semua syarat dan ketentuan yang
          disebutkan di halaman ini. Terminologi berikut berlaku untuk Syarat
          dan Ketentuan ini, Pernyataan Privasi dan Pemberitahuan Sanggahan dan
          semua Perjanjian: "Klien", "Anda" dan "Milik Anda" mengacu pada Anda,
          orang yang masuk ke situs web ini dan mematuhi syarat dan ketentuan
          Perusahaan. "Perusahaan", "Diri Kami", "Kami", "Milik Kami", dan
          "Kami", mengacu pada Perusahaan kami. "Pihak", "Pihak", atau "Kami",
          mengacu pada Klien dan diri kami sendiri. Semua istilah mengacu pada
          penawaran, penerimaan, dan pertimbangan pembayaran yang diperlukan
          untuk melakukan proses bantuan kami kepada Klien dengan cara yang
          paling tepat untuk tujuan yang jelas dalam memenuhi kebutuhan Klien
          sehubungan dengan penyediaan layanan yang dinyatakan Perusahaan,
          sesuai dengan dan tunduk pada, hukum yang berlaku di Belanda. Setiap
          penggunaan terminologi di atas atau kata lain dalam bentuk tunggal,
          jamak, huruf besar dan / atau dia, dianggap dapat dipertukarkan dan
          oleh karena itu merujuk pada yang sama.
        </p>

        <h3>
          <br />
          Cookie
        </h3>
        <hr />
        <p style="text-align: justify">
          Kami menggunakan penggunaan cookie. Dengan mengakses Mystoku, Anda
          setuju untuk menggunakan cookie sesuai dengan Kebijakan Privasi
          Mystoku. Sebagian besar situs web interaktif menggunakan cookie untuk
          memungkinkan kami mengambil detail pengguna untuk setiap kunjungan.
          Cookie digunakan oleh situs web kami untuk mengaktifkan fungsionalitas
          area tertentu untuk memudahkan orang mengunjungi situs web kami.
          Beberapa afiliasi / mitra periklanan kami juga dapat menggunakan
          cookie.
        </p>

        <h3>
          <br />
          Lisensi
        </h3>
        <hr />
        <p style="text-align: justify">
          Kecuali dinyatakan lain, Mystoku dan / atau pemberi lisensinya
          memiliki hak kekayaan intelektual untuk semua materi di Mystoku. Semua
          hak kekayaan intelektual dilindungi. Anda dapat mengakses ini dari
          Mystoku untuk penggunaan pribadi Anda dengan tunduk pada batasan yang
          ditetapkan dalam syarat dan ketentuan ini. Anda tidak harus:
        </p>
        <ul style="text-align: justify">
          <li>1. Publikasikan ulang materi dari Mystoku</li>
          <li>2. Jual, sewa, atau sub-lisensi materi dari Mystoku</li>
          <li>3. Reproduksi, duplikat, atau salin materi dari Mystoku</li>
          <li>4. Mendistribusikan kembali konten dari Mystoku</li>
        </ul>
        <p style="text-align: justify" class="pt-2"> Perjanjian ini akan dimulai pada tanggal perjanjian ini. <br /></p>
        <p style="text-align: justify">

          Bagian dari situs web ini menawarkan kesempatan bagi pengguna untuk
          memposting dan bertukar pendapat dan informasi di area tertentu di
          situs web. Mystoku tidak memfilter, mengedit, menerbitkan, atau
          meninjau Komentar sebelum kehadirannya di situs web. Komentar tidak
          mencerminkan pandangan dan pendapat Mystoku, agen dan / atau
          afiliasinya. Komentar mencerminkan pandangan dan opini orang yang
          memposting pandangan dan opini mereka. Sejauh diizinkan oleh
          undang-undang yang berlaku, Mystoku tidak akan bertanggung jawab atas
          Komentar atau atas kewajiban, kerusakan atau biaya yang disebabkan dan
          / atau diderita sebagai akibat dari penggunaan dan / atau posting dan
          / atau penampilan Komentar tentang ini. situs web. <br />

        </p>
        <p style="text-align: justify" class="pt-2">  Mystoku berhak untuk memantau semua Komentar dan menghapus setiap
          Komentar yang dapat dianggap tidak pantas, menyinggung, atau
          menyebabkan pelanggaran Syarat dan Ketentuan ini. <br /></p>

        <p style="text-align: justify" class="pt-2">  Anda menjamin dan menyatakan bahwa: <br /></p>
        <ul style="text-align: justify">
          <li>1. Anda berhak untuk memposting Komentar di situs web kami dan memiliki semua lisensi dan persetujuan yang diperlukan untuk melakukannya;</li>
          <li>2. Komentar tidak melanggar hak kekayaan intelektual apa pun, termasuk tanpa batasan hak cipta, paten, atau merek dagang pihak ketiga mana pun;</li>
          <li>3. Komentar tidak mengandung materi yang memfitnah, memfitnah, menyinggung, tidak senonoh atau melanggar hukum yang merupakan pelanggaran privasi</li>
          <li>4. Komentar tidak akan digunakan untuk meminta atau mempromosikan bisnis atau kebiasaan atau menyajikan aktivitas komersial atau aktivitas yang melanggar hukum.</li>
        </ul>
        <p style="text-align: justify" class="pt-2">Anda dengan ini memberi Mystoku lisensi non-eksklusif untuk menggunakan, mereproduksi, mengedit, dan mengizinkan orang lain untuk menggunakan, mereproduksi, dan mengedit Komentar Anda dalam segala bentuk, format, atau media. <br></p>
        <h3><br> Cara untuk Menghubungi Kami</h3>
        <hr>
        <p style="text-align:justify;">
        Jika Anda memiliki pertanyaan lebih lanjut tentang privasi dan keamanan informasi Anda dan ingin memperbarui atau menghapus data Anda 
        maka silakan hubungi kami di: <span style="color: #6498fe">admin@mystoku.com</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
p {
  font-size: 18px;
  color: black;
  margin: 0px 0px 20px;
}
li {
  font-size: 18px;
  margin: 0px 0px 7px;
}
</style>