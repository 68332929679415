<template>
  <div>
    <footer id="footer" class="footer-area">
          <div class="container">
              <div class="footer-widget pb-100 text-left">
                  <div class="row">
                      <div class="col-lg-3 col-md-6 col-sm-8">
                          <div class="footer-about mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                              <a class="logo" href="#">
                                  <img src="@/assets/images/logowhite.svg" alt="logo">
                              </a>
                              <p class="text">Mystoku - Point Of Sale <br>
                                  Jadikan bisnis Anda lebih mudah <br>
                                  Kami membantu bisnis Anda mempercepat proses penjualan dengan aplikasi kasir berbasis self service.
                                  </p>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-7 col-sm-7">
                          <div class="footer-link d-flex mt-50 justify-content-md-between">
                              <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                                  <div class="footer-title">
                                      <h4 class="title">Layanan</h4>
                                  </div>
                                  <ul class="link">
                                      <li @click="scrollToTop()"><router-link :to="{ name: 'Fitur'}"> Fitur Lengkap</router-link></li>
                                      <li @click="scrollToTop()"><router-link to="#">Testimoni</router-link></li>
                                  </ul>
                              </div> <!-- footer wrapper -->
                          </div> <!-- footer link -->
                      </div>
                      <div class="col-lg-3 col-md-7 col-sm-7">
                          <div class="footer-link d-flex mt-50 justify-content-md-between">
                              <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                                  <div class="footer-title">
                                      <h4 class="title">Bantuan</h4>
                                  </div>
                                  <ul class="link">
                                      <li @click="scrollToTop()"><router-link :to="{ name: 'Bantuan'}">Pusat Bantuan</router-link></li>
                                      <li @click="scrollToTop()"><router-link :to="{ name: 'SyaratKetentuan'}">Syarat dan Ketentuan</router-link></li>
                                      <li @click="scrollToTop()"><router-link :to="{ name: 'KebijakanPrivasi'}">Kebijakan Privasi</router-link></li>
                                  </ul>
                              </div> <!-- footer wrapper -->
                          </div> <!-- footer link -->
                      </div>
                      <div class="col-lg-3 col-md-7 col-sm-7">
                          <div class="footer-link d-flex mt-50 justify-content-md-between">
                              <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                                  <div class="footer-title">
                                      <h4 class="title">Kontak</h4>
                                  </div>
                                  <ul class="link">
                                      <li><a >+6284545444</a></li>
                                      <li><a >admin@mystoku.com</a></li>
                                  </ul>
                              </div> <!-- footer wrapper -->
                          </div> <!-- footer link -->
                      </div>
                  </div> <!-- row -->
              </div> <!-- footer widget -->
              <div class="footer-copyright">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="copyright d-sm-flex justify-content-between">
                              <div class="footer-about wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                  <ul class="social">
                                      <li><a href=""><i class="lni lni-whatsapp"></i></a></li>
                                      <li><a href="https://www.instagram.com/mystoku"><i class="lni lni-instagram-filled"> </i></a></li>
                                  </ul>
                              </div> <!-- footer about -->
                              <div class="copyright-content ml-auto">
                                  <p class="text">Copyright © 2020 Mystoku</p>
                              </div> <!-- copyright content -->
                          </div> <!-- copyright -->
                      </div>
                  </div> <!-- row -->
              </div> <!-- footer copyright -->
          </div> <!-- container -->
          <div id="particles-2"></div>
      </footer>
      <a href="#" class="back-to-top"><i class="lni lni-chevron-up"></i></a>
  </div>
</template>

<script>
export default {
  methods:{
    scrollToTop() {
        window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: hsla(0,0%,100%,.96);;
  color: #888;
  text-align: center;
  border-top: 1px solid rgba(0,0,0,0.1);
}
@media (max-width: 425px){
  .txt{
    display: none;
  }
}
.footer-area::before {
  background-image: none !important;
  background-color: black;
}

.link-wrapper .link li a {
    font-size: 15px !important;
}

.footer-about .text {
    font-size: 15px;
}
</style>